<template>
<div class="container">
    <div class="top" id="bloc-0">
        <voice-header></voice-header>
    </div>
    <div class="main" style="min-height: 600px">
        <div class="main_title" style="min-height: 100px;">
            <div>
              <H1 class="main_title_p01">{{ $t('speechma.hero.title') }}</H1>
            </div>
            <div>
              <H2 class="main_title_p02">{{ $t('speechma.hero.subtitle') }}</H2>
            </div>
        </div>
        <div class="ttscom_div">
            <div class="ttscom_content_wrapper">
                <!-- 左侧面板 -->
                <div class="ttscom_left_panel">
                    <!-- 预置词 -->
                    <div class="preset-prompts-container">
                        <button 
                            v-for="index in getPresetTextCount()" 
                            :key="index"
                            class="preset-tag"
                            :class="{ 'active': activePresetIndex === index }"
                            @click="setPresetText(index)">
                            {{ $t(`txt2voice.preset_texts.${index}.tag`) }}
                        </button>
                    </div>
                    
                    <!-- 文本框 -->
                    <div class="ttscom_teatarea">
                        <textarea
                            v-model="voicetext"
                            id="voicetextarea"
                            type="text"
                            :placeholder="placeholderText"
                            @input="handleInputOptimized"
                            @focus="handleFocusOptimized"
                            :maxlength="STATIC_CONFIG.text_maxlen"
                            @blur="handleBlurOptimized"
                            @compositionstart="handleCompositionOptimized"
                            @compositionend="handleCompositionOptimized"
                            class="ttscom_textarea_style"
                            :class="{ 'composing': isComposing }"
                            aria-label="tiktok text to speech textarea">
                        </textarea>
                        <div class="ttscom_word_counter">{{ wordcnt }}/{{ STATIC_CONFIG.text_maxlen }}</div>
                    </div>
                </div>

                <!-- 右侧面板 -->
                <div class="ttscom_right_panel">
                    <div class="ttscom_control_panel">
                        <!-- 语言选择 -->
                        <div class="ttscom_control_select_wrapper">
                            <select v-model.lazy="selectLang" 
                                @change="debouncedChangeLang" 
                                class="ttscom_control_select"
                                aria-label="tiktok text to speech select lang">
                                <option v-for="item in filteredLangs" 
                                    v-bind:key="item.value" 
                                    v-bind:value="item.value">
                                    {{ item.label }}
                                </option>
                            </select>
                        </div>

                        <!-- 语音列表 -->
                        <div class="ttscom_voice_select_container">
                            <div class="ttscom_voice_list"
                                @scroll="handleScroll"
                                @mousedown="handleSelectMouseDown">
                                <div v-for="option in filteredModels"
                                    :key="option.value"
                                    class="ttscom_voice_option"
                                    :class="{ 'selected': selectModel === option.value }"
                                    @click="selectVoice(option)">
                                    <div class="ttscom_voice_option_content">
                                        <span class="ttscom_voice_label">{{ option.label }}</span>
                                        <button 
                                            class="ttscom_voice_preview_button"
                                            @click.stop="previewVoice(option)"
                                            aria-label="tiktok text to speech voice preview button"
                                            :class="{ 
                                                'playing': isPreviewPlaying && currentPreviewVoice === option.value,
                                                'loading': isPreviewLoading && currentPreviewVoice === option.value 
                                            }">
                                            <template v-if="isPreviewLoading && currentPreviewVoice === option.value">
                                                <span class="loading-spinner"></span>
                                            </template>
                                            <template v-else-if="isPreviewPlaying && currentPreviewVoice === option.value">
                                                <span>{{ $t('txt2voice.audio_pause') }}</span>
                                            </template>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 生成按钮容器 -->
                <div class="ttscom_generate_container">
                    <button
                        ref='gen_button'
                        class="ttscom_generate_button"
                        :class="{ 'loading': isLoading }"
                        v-on:click="handleGenVoiceClick"
                        v-bind:disabled="disabled || isLoading">
                        <span v-if="!isLoading">{{ $t('txt2voice.main_genvoice') }}</span>
                        <span v-else class="loading-spinner"></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="audio-controls-container">
            <div class="audio-controls" :class="{ 
                'visible': showAudioControls && selectModelWavplay && !isLoading,
                'hidden': isLoading 
                }">
                <button 
                    class="control-button play-button"
                    @click="togglePlay"
                    :disabled="!canPlay"
                    :class="{ 
                        'loading': isPlayLoading,
                        'playing': isPlaying 
                    }"
                    >
                    <span v-if="isPlayLoading" class="loading-spinner"></span>
                    <span v-if="!isPlayLoading">{{ isPlaying ? $t('txt2voice.audio_pause') : $t('txt2voice.audio_play') }}</span>
                </button>
                <button 
                    class="control-button download-button"
                    @click="handleDownload"
                    :disabled="!selectModelWavplay || isDownloading"
                    :class="{ 'downloading': isDownloading }"
                  >
                    <span v-if="isDownloading" class="loading-spinner"></span>
                    <span v-if="!isDownloading">{{ $t('txt2voice.audio_download') }}</span>
                </button>
            </div>
        </div>
        <audio ref="audio_main" style="display: none;">
        </audio>
        <div class="faq_section">
            <H2 class="main_div_quest_about">{{ $t('speechma.faq.title') }}</H2>
  
            <!-- FAQ手风琴部分 -->
            <div class="faq-accordion">
                <div v-for="i in 10" :key="i" class="faq-item">
                    <div class="faq-header" @click="toggleFaq(i)">
                        <H3 class="main_div_quest">{{ $t(`speechma.faq.items.${i}.question`) }}</H3>
                        <span class="faq-icon" :class="{ 'active': activeFaq === i }">›</span>
                    </div>
                    <div class="faq-content" :class="{ 'active': activeFaq === i }">
                        <p class="main_div_answer">{{ $t(`speechma.faq.items.${i}.answer`) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="popupContainer">
        <PopupModal
            v-for="(data, id) in popupData"
            :key="id"
            :data="data"
            :pricing-href="pricing_href"
            v-if="popupStates[id]"
            @close="closePopup(id)"
        />
    </div>
    <div class="footer" id="bloc-10">
        <div>
            <voice-footer></voice-footer>
        </div>
    </div>
    <PopupDownload ref="popupDownload" />
    <GoogleSignInModal 
        :visible.sync="isSignInVisible"
        @credential-response="handleCredentialResponse"
    />
</div>
</template>
  
  <script>
  import { mapGetters } from 'vuex'
  import { defineAsyncComponent } from 'vue'
  import { reactive } from 'vue'
  import axios from 'axios'
  import debounce from 'lodash/debounce';
  import { setCookie, delCookie, getCookie } from '../utils/cookies';
  import VoiceHeader from '@/components/VoiceHeader.vue'
  import { handleGoogleAuth } from '../utils/auth' 
  import { reportError } from '../utils/errorReporter'
  import { trackAction } from '../utils/actionReporter'
  import { onLCP } from 'web-vitals/attribution';
  import { reportWebVital } from '../utils/reportWebVital'
  
  // 静态配置
  const STATIC_CONFIG = Object.freeze({
      api_host: 'https://tiktokvoice.net',
      text_maxlen: 300,
      POPUP_TYPES: {
          NOLOGIN: 'popup_nologin',
          NOSUB: 'popup_nosub',
          SUBLIMIT: 'popup_sublimit'
      },
      faqAnswerCounts: {
          1: 4,
          2: 4,
          3: 5,
          4: 2,
          5: 3,
          6: 3,
          7: 3,
          8: 3,
          9: 3,
          10: 8,
          11: 1,  
          12: 1  
      }
  })
  export default {
      name: 'home',
      components: {
          VoiceHeader,
          VoiceFooter: defineAsyncComponent({
              loader: () => import('../components/VoiceFooterIndex.vue'),
              timeout: 2000,
          }),
          PopupModal: defineAsyncComponent({
              loader: () => import('../components/PopupModal.vue'),
              timeout: 2000
          }),
          PopupDownload: defineAsyncComponent({
              loader: () => import('../components/PopupDownload.vue'),
              timeout: 2000
          }),
          GoogleSignInModal: defineAsyncComponent({
              loader: () => import('../components/GoogleSignInModal.vue'),
              timeout: 2000
          })
      },
      data() {
          return {
              STATIC_CONFIG,
              langsModels: null,
              allLangs: null,
              selectLang: null,
              selectLangModels: null,
              selectModel: null,
              selectModelWavplay: '',
              modelcat: '',  
              modelname: '', 
              voicetext: '',
              disabled: false,
              wordcnt: 0,
              email: '',
              user_subscript: 0,  // 有4个取值, 0 未订阅, 1 已订阅, 2 虽,字符已用完
              pricing_href: '/en/pricing',
              isComposing: false,
              inputTimer: null,
              pageSize: 25,
              currentPage: 0, 
              isLoadingMore: false, 
              isLoading: false,
              isDownloading: false,
              isSignInVisible: false,
              popupData: {
                  // 未登录用户
                  [STATIC_CONFIG.POPUP_TYPES.NOLOGIN]: {
                      title: this.$i18n.t('txt2voice.popup_nologin_title'),
                      description: this.$i18n.t('txt2voice.popup_nologin_desc'),
                      buttonText: this.$i18n.t('txt2voice.popup_nologin_btntext'),
                  },
                  // 登录用户,未订阅
                  [STATIC_CONFIG.POPUP_TYPES.NOSUB]: {
                      title: this.$i18n.t('txt2voice.popup_nosub_title'),
                      description: this.$i18n.t('txt2voice.popup_nosub_desc'),
                      buttonText: this.$i18n.t('txt2voice.popup_nosub_btntext'),
                  },
                  // 登录用户, 已达到订阅套餐上限
                  [STATIC_CONFIG.POPUP_TYPES.SUBLIMIT]: {
                      title: this.$i18n.t('txt2voice.popup_sublimit_title'),
                      description: this.$i18n.t('txt2voice.popup_sublimit_desc'),
                      buttonText: this.$i18n.t('txt2voice.popup_sublimit_btntext'),
                  }
              },
              popupStates: reactive({}),
              activeFaq: null,
              isPreviewLoading: false,
              isPlaying: false,
              showAudioControls: false,
              isPlayLoading: false,
              isPreviewPlaying: false,
              activePresetIndex: 1,
              currentPreviewVoice: null
          }
      },
      head() {
          return {
              'title': this.$i18n.t('speechma.head.title'),
              'keywords': this.$i18n.t('speechma.head.keywords'),
              'description': this.$i18n.t('speechma.head.description')
          }
      },
      metaInfo() {
          return {
              link: [
                  { rel: 'alternate', hreflang: 'x-default', href: 'https://tiktokvoice.net/en/speechma' },
                  { rel: 'alternate', hreflang: 'en', href: 'https://tiktokvoice.net/en/speechma' },
                  { rel: 'alternate', hreflang: 'ja', href: 'https://tiktokvoice.net/ja/speechma' },
                  { rel: 'alternate', hreflang: 'zh', href: 'https://tiktokvoice.net/zh/speechma' },
                  { rel: 'alternate', hreflang: 'zh-tw', href: 'https://tiktokvoice.net/zh-tw/speechma' },
                  { rel: 'alternate', hreflang: 'ko', href: 'https://tiktokvoice.net/ko/speechma' },
                  { rel: 'alternate', hreflang: 'vi', href: 'https://tiktokvoice.net/vi/speechma' },
                  { rel: 'alternate', hreflang: 'th', href: 'https://tiktokvoice.net/th/speechma' },
                  { rel: 'alternate', hreflang: 'hi', href: 'https://tiktokvoice.net/hi/speechma' },
                  { rel: 'alternate', hreflang: 'fa', href: 'https://tiktokvoice.net/fa/speechma' },
                  { rel: 'alternate', hreflang: 'ru', href: 'https://tiktokvoice.net/ru/speechma' },
                  { rel: 'alternate', hreflang: 'de', href: 'https://tiktokvoice.net/de/speechma' },
                  { rel: 'alternate', hreflang: 'fr', href: 'https://tiktokvoice.net/fr/speechma' },
                  { rel: 'alternate', hreflang: 'ro', href: 'https://tiktokvoice.net/ro/speechma' },
                  { rel: 'alternate', hreflang: 'cs', href: 'https://tiktokvoice.net/cs/speechma' },
                  { rel: 'alternate', hreflang: 'es', href: 'https://tiktokvoice.net/es/speechma' },
                  { rel: 'alternate', hreflang: 'pt', href: 'https://tiktokvoice.net/pt/speechma' }, 
                  { rel: 'alternate', hreflang: 'bn', href: 'https://tiktokvoice.net/bn/speechma' },
                  { rel: 'alternate', hreflang: 'it', href: 'https://tiktokvoice.net/it/speechma' },
                  { rel: 'alternate', hreflang: 'ar', href: 'https://tiktokvoice.net/ar/speechma' },
                  { rel: 'alternate', hreflang: 'ur', href: 'https://tiktokvoice.net/ur/speechma' },
                  { rel: 'alternate', hreflang: 'ms', href: 'https://tiktokvoice.net/ms/speechma' },
                  { rel: 'alternate', hreflang: 'tr', href: 'https://tiktokvoice.net/tr/speechma' },
                  { rel: 'alternate', hreflang: 'pl', href: 'https://tiktokvoice.net/pl/speechma' },
                  { rel: 'alternate', hreflang: 'nl', href: 'https://tiktokvoice.net/nl/speechma' },
                  { rel: 'alternate', hreflang: 'uk', href: 'https://tiktokvoice.net/uk/speechma' },
              ]
          }
      },
      asyncData: function ({ store, route }) {
          // return store.dispatch("fetchData")
          return ;
      },
      computed: {
          ...mapGetters(['isLoggedIn', 'currentUser', 'authToken']),
          placeholderText() {
          return this.$i18n.locale === 'en' 
              ? '' 
                  : this.$t('txt2voice.main_textarea_holder');
          },
          filteredLangs() {
              return this.allLangs?.slice(0, 25);
          },
          filteredModels() {
              if (!this.selectLangModels) return [];
              return this.selectLangModels.slice(0, 120); 
          },
          canPlay() {
              return !!(this.selectModelWavplay && !this.isLoading);
          }
      },
      watch: {
          isLoggedIn(newValue) {
              if (newValue && this.currentUser) {
                  this.getUserInfo(this.currentUser.email);
              }
          },
          '$i18n.locale'(newVal) {
              if (newVal !== 'en') {
                  this.activePresetIndex = null;
              }
          }
      },
      methods: {
          async playAudio(audioElement, audioSource) {
              if (!audioElement || !audioSource) {
                  reportError(new Error('Audio element or source not found'), 'playAudio');
                  return;
              }
              try {
                  if (!audioElement.paused) {
                      await audioElement.pause();
                  }
                  audioElement.currentTime = 0;
                  audioElement.src = `${STATIC_CONFIG.api_host}${audioSource}`;
                  await new Promise((resolve, reject) => {
                      const loadHandler = () => {
                          audioElement.removeEventListener('canplay', loadHandler);
                          audioElement.removeEventListener('error', errorHandler);
                          resolve();
                      };
                      const errorHandler = (error) => {
                          audioElement.removeEventListener('canplay', loadHandler);
                          audioElement.removeEventListener('error', errorHandler);
                          reject(error);
                      };
                      audioElement.addEventListener('canplay', loadHandler);
                      audioElement.addEventListener('error', errorHandler);
                      audioElement.load();
                  });
  
                  await audioElement.play();
              } catch (error) {
                  reportError(error, 'playAudio - playback failed');
              }
          },
          async playPreviewAudio() {
              if (this.isPreviewLoading || !this.selectModelWavplay) return;
              
              const audio = this.$refs.audio_main;
              
              // 如果正在播放,则暂停
              if (this.isPreviewPlaying) {
                  requestAnimationFrame(() => {
                      audio.pause();
                      this.isPreviewPlaying = false;
                  });
                  return;
              }
  
              this.isPreviewLoading = true;
              try {
                  await this.playAudio(audio, this.selectModelWavplay);
                  requestAnimationFrame(() => {
                      this.isPreviewPlaying = true;
                  });
                  
                  // 监听播放结束事件
                  audio.onended = () => {
                      this.isPreviewPlaying = false;
                      audio.onended = null;
                  };
                  // trackAction({
                  //     email: this.email,
                  //     action: 'home-preview-audio',
                  //     domain: 'tiktokvoice.net',
                  //     modelcat: this.modelcat,
                  //     modelname: this.selectModel
                  // });
              } catch (error) {
                  reportError(error, 'playPreviewAudio');
              } finally {
                  this.isPreviewLoading = false;
              }
          },
          togglePlay() {
              const audio = this.$refs.audio_main;
              if (!audio || !this.selectModelWavplay) {
                  reportError(new Error('Audio element or source not found'), 'togglePlay');
                  return;
              }
              if (this.isPlaying) {
                  audio.pause();
                  this.isPlaying = false;
                  return;
              }
              this.isPlayLoading = true;
              this.playAudio(audio, this.selectModelWavplay)
                  .then(() => {
                      this.isPlaying = true;
                      audio.onended = () => {
                          this.isPlaying = false;
                          audio.onended = null;
                      };
                      trackAction({
                          email: this.email,
                          action: 'home-play-audio',
                          domain: 'tiktokvoice.net',
                          modelcat: this.modelcat,
                          modelname: this.modelname
                      });
                  })
                  .catch(error => {
                      reportError(error, 'togglePlay - playback failed');
                      this.isPlaying = false;
                  })
                  .finally(() => {
                      this.isPlayLoading = false;
                  });
          },
          changeLang: function (evt) {
              var value = evt.target.value
              if (evt != null && (value in this.langsModels)) {
                  this.selectlang = value
                  this.selectLangModels = this.langsModels[value].slice()
                  this.selectModel = this.selectLangModels[0].value
                  this.selectModelWavplay = this.selectLangModels[0].wavplay
                  const textarea = document.getElementById('voicetextarea')
                  if (textarea && !textarea.value) {
                      textarea.value = textarea.getAttribute('placeholder')
                  }
                  this.showAudioControls = false;
              }
          },
          debouncedChangeLang: debounce(function(evt) {
              this.changeLang(evt);
          }, 50),
          wordCnt() {
              const text = this.voicetext?.trim() || '';
              requestAnimationFrame(() => {
                  if (text.length > STATIC_CONFIG.text_maxlen) {
                      this.voicetext = text.slice(0, STATIC_CONFIG.text_maxlen)
                  }
                  this.wordcnt = text.length
              })
          },    
          handleCompositionOptimized(event) {
              requestAnimationFrame(() => {
                  this.isComposing = event.type === 'compositionstart'
                  if (event.type === 'compositionend') {
                      queueMicrotask(() => {
                          this.handleInputOptimized(event)
                      })
                  }
              })
          },
          handleInputOptimized: debounce(function(event) {
              if (this.isComposing) return
              Promise.resolve().then(() => {
                  requestAnimationFrame(() => {
                      const value = event.target.value
                      this.$nextTick(() => {
                          this.voicetext = value
                          this.wordCnt()
                      })
                  })
              })
          }, 16),
          handleFocusOptimized(event) {
              requestAnimationFrame(() => {
                  const target = event.target;
                  const placeholder = target.getAttribute('placeholder');
                  if (target.value === placeholder) {
                      target.value = '';
                  }
              });
          },
          handleBlurOptimized(event) {
              requestAnimationFrame(() => {
                  const target = event.target;
                  if (!target.value.trim()) {
                      const placeholder = target.getAttribute('placeholder');
                      target.value = placeholder;
                  }
              });
          },
          handleSelectMouseDown(event) {
              // 使用 requestAnimationFrame 优化滚动检测
              if (!this.isLoadingMore) {
                  requestAnimationFrame(() => {
                      const target = event.target;
                      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 50) {
                          this.loadMoreOptions();
                      }
                  });
              }
          },
          async loadMoreOptions() {
              if (this.isLoadingMore) return;
              this.isLoadingMore = true;
              try {
                  await new Promise(resolve => requestAnimationFrame(resolve));
                  this.currentPage++;
              } finally {
                  this.isLoadingMore = false;
              }
          },
          handleScroll: debounce(function(event) {
              requestAnimationFrame(() => {
                  const select = event.target;
                  if (select.scrollTop + select.clientHeight >= select.scrollHeight - 50) {
                      this.loadMoreOptions();
                  }
              });
          }, 50),
          debouncedChangeModel: debounce(function(evt) {
              requestAnimationFrame(() => {
                  this.changeModel(evt);
              });
          }, 50),
          changeModel(evt) {
              const value = evt.target.value;
              if (!value || !this.selectLang || !(this.selectLang in this.langsModels)) return;
              const model = this.langsModels[this.selectLang].find(m => m.value === value);
              if (!model) return;
              
              requestAnimationFrame(() => {
                  if (this.$refs.audio_main) {
                      const audio = this.$refs.audio_main;
                      if (!audio.paused) {
                          audio.pause();
                      }
                      this.isPlaying = false;
                      this.isPreviewPlaying = false;
                  }
                  this.selectModelWavplay = model.wavplay;
                  this.showAudioControls = false;
              });
          },
          async updateAudioElements(audioUrl) {
              try {
                  if (!this.$refs.audio_main) {
                      reportError(new Error('Audio element not found'), 'updateAudioElements');
                      return;
                  }
                  this.selectModelWavplay = audioUrl;
                  this.showAudioControls = true;
              } catch (error) {
                  reportError(error, 'updateAudioElements');
              }
          },
          async updateCharacterCounter(textlen) {
              if (!this.email) return
              try {
                  const uri = `${STATIC_CONFIG.api_host}/lapi/counter`
                  const formData = new FormData()
                  formData.append('email', this.email)
                  formData.append('textlen', textlen)
                  await axios.post(uri, formData, {
                      headers: { 'Content-Type': 'multipart/form-data' }
                  })
              } catch (error) {
                  reportError(error, 'updateCharacterCounter')
              }
          },
          genVoice: async function () {
              this.showAudioControls = false;
              if (!this.voicetext?.trim()) {
                  alert(this.$i18n.t('txt2voice.main_input_empty'))
                  return false
              }
              // 去掉生成时的登录
              // if (!this.isLoggedIn) {
              //   this.isSignInVisible = true; 
              //   return;
              // }
              const model = this.selectModel.split('+')
              this.modelcat = model[0]
              this.modelname = model[1]
              const formdata = {
                  modelcat: this.modelcat ,
                  modelname: this.modelname,
                  text: this.voicetext.slice(0, STATIC_CONFIG.text_maxlen),
                  subscript: this.user_subscript,
                  email: this.email,
                  userid: 0,
                  t: 1
              }
              try {
                  const uri = `${STATIC_CONFIG.api_host}/api/genaudio`
                  const { data } = await axios.post(uri, formdata, {
                      headers: { 
                          'Content-Type': 'application/json; charset=utf-8', 
                          'Cache-Control': 'no-cache',
                          'Pragma': 'no-cache'
                      },
                        
                  })
                  switch (data.ret) {
                      case 0:
                          await Promise.all([
                              this.updateAudioElements(`${data.uri}`),
                              this.updateCharacterCounter(data.textlen)
                          ])
                          this.showAudioControls = true 
                          trackAction({
                              email: this.email,
                              action: 'home-gen-audio',
                              domain: 'tiktokvoice.net',
                              modelcat: this.modelcat,
                              modelname: this.modelname
                          });
                          break
                      case 2:
                          const popid = this.isLoggedIn
                              ? (this.user_subscript === 2 ? STATIC_CONFIG.POPUP_TYPES.SUBLIMIT : STATIC_CONFIG.POPUP_TYPES.NOSUB)
                              : STATIC_CONFIG.POPUP_TYPES.NOLOGIN
                          this.openPopup(popid)
                          trackAction({
                              email: this.email,
                              action: 'home-gen-popup',
                              domain: 'tiktokvoice.net',
                              modelcat: this.modelcat,
                              modelname: this.modelname
                          });
                          break
                      default:
                          trackAction({
                              email: this.email,
                              action: 'home-gen-failed',
                              domain: 'tiktokvoice.net',
                              modelcat: this.modelcat,
                              modelname: this.modelname
                          });
                          alert(data.msg)
                  }
              } catch (error) {
                  reportError(error, 'genVoice generator error')
                  alert('An error occurred while generating the voice, please try again later!')
              }
          },
          async getUserInfo (email) {
              if (!email) {
                  // console.log("email is empty, email:" + email)
                  return false
              }
              try {
                  const uri = `${STATIC_CONFIG.api_host}/lapi/user/profile`
                  const { data } = await axios.get(uri, {
                      params: { email },
                      headers: { 
                          'Content-Type': 'application/json; charset=utf-8',
                          'Cache-Control': 'no-cache',
                          'Pragma': 'no-cache'
                      }
                  })
  
                  if (data.ret === 0 && data.user_info) {
                      this.email = data.user_info.email
                      this.user_subscript = data.user_info.user_subscript
                  } else {
                      console.log("ret:" + data.ret + ", msg:" + data.msg)
                  }
              } catch (error) {
                  reportError(error, 'getUserInfo')
              }
          },
          async handleGenVoiceClick() {
              if (this.isLoading) return;
              // 立即更新按钮状态
              requestAnimationFrame(() => {
                  this.isLoading = true;
              });
              try {
                  await this.genVoice();
              } finally {
                  requestAnimationFrame(() => {
                      this.isLoading = false;
                  });
              }
          },
          async handleCredentialResponse(response) {
              try {
                  const apiUrl = `${STATIC_CONFIG.api_host}/lapi/auth/google`
                  const success = await handleGoogleAuth?.handleCredentialResponse?.(response, this.$store, apiUrl)
                  if (success) {
                      this.isSignInVisible = false
                      trackAction({
                          email: this.email,
                          action: 'home-login-success',
                          domain: 'tiktokvoice.net',
                          modelcat: this.modelcat,
                          modelname: this.modelname
                      });
                  } else {
                      trackAction({
                          email: this.email,
                          action: 'home-login-failed',
                          domain: 'tiktokvoice.net',
                          modelcat: this.modelcat,
                          modelname: this.modelname
                      });
                      reportError(new Error('Authentication failed'), 'handleCredentialResponse failed')
                      this.$emit('login-error', 'Authentication failed')
                  }
              } catch (error) {
                  reportError(error, 'handleCredentialResponse')
                  this.$emit('login-error', error.message || 'Authentication failed')
              }
          },
          async handleDownload() {
              if (!this.selectModelWavplay) {
                  alert('There is no audio! Please generate audio first!');
                  return;
              }
              if (this.isDownloading) {
                  return;
              }
              if (!this.isLoggedIn) {
                  this.isSignInVisible = true; 
                  trackAction({
                          email: this.email,
                          action: 'home-downpopup-login',
                          domain: 'tiktokvoice.net',
                          modelcat: this.modelcat,
                          modelname: this.modelname
                      });
                  return;
              }
              if (this.user_subscript !== 1) {
                  try {
                      const uri = `${STATIC_CONFIG.api_host}/lapi/actioncounts`
                      const params = {};
                      params.action = 'home-download-success';
                      if (this.email) {
                          params.email = this.email;
                      }
                      const { data } = await axios.get(uri, {
                          params,
                          headers: { 'Content-Type': 'application/json; charset=utf-8' },
                          timeout: 3000  // 3s超时
                      })
  
                      if (data.ret === 0 && data.count >= 1) {
                          // 当日下载超过2次， 显示弹窗
                          this.$refs.popupDownload.openPopup();
                          trackAction({
                              email: this.email,
                              action: 'home-downpopup-subscript',
                              domain: 'tiktokvoice.net',
                              modelcat: this.modelcat,
                              modelname: this.modelname
                          });
                          return ;
                      }
                  } catch (error) {
                      reportError(error, 'handleDownload user subscript!')
                  }
              }
              this.isDownloading = true;
              let url;
              let response;
              try {
                  response = await fetch(`${STATIC_CONFIG.api_host}${this.selectModelWavplay}`, {
                      headers: {
                          'Cache-Control': 'no-cache',
                          'Pragma': 'no-cache'
                      },
                      mode: 'cors',
                      credentials: 'same-origin'
                  });
                  if (!response.ok) {
                      reportError(new Error(`Download failed with status: ${response.status}`), 'handleDownload');
                      alert('Audio download failed, Please try later!');
                      return;
                  }
                  const blob = await response.blob();
                  if (!blob || blob.size === 0) {
                      reportError(new Error('Invalid blob data received'), 'handleDownload');
                      alert('Audio blob download failed, Please try later!');
                      return ;
                  }
                  url = window.URL.createObjectURL(blob);
                  const filename = `tiktokvoice.net-${new Date().getTime()}.mp3`;
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  trackAction({
                      email: this.email,
                      action: 'home-download-success',
                      domain: 'tiktokvoice.net',
                      modelcat: this.modelcat,
                      modelname: this.modelname
                  });
              } catch (error) {
                  reportError(error, 'handleDownload down error');
                  alert('Failed to download audio file, please try later!');
              } finally {
                  if (url) {
                      window.URL.revokeObjectURL(url);
                  }
                  this.isDownloading = false;
              }
          },
          initializePopups() {
              Object.keys(this.popupData).forEach(id => {
                  this.popupStates[id] = false
              })
          },
          openPopup(id) {
              if (this.popupData[id]) {
                  this.popupStates[id] = true
              }
          },
          closePopup(id) {
              if (this.popupStates[id] !== undefined) {
                  this.popupStates[id] = false
              }
              this.$forceUpdate();
          },
          handleFocusOptimized: debounce(function(event) {
              if (event.target.value === event.target.getAttribute('placeholder')) {
                  event.target.value = '';
              }
          }, 50),
          handleBlurOptimized: debounce(function(event) {
              if (!event.target.value) {
                  event.target.value = event.target.getAttribute('placeholder');
              }
          }, 50),
          toggleFaq(index) {
              if (this.activeFaq === index) {
                  this.activeFaq = null;
              } else {
                  this.activeFaq = index;
              }
          },
          getAnswerCount(index) {
              return STATIC_CONFIG.faqAnswerCounts[index] || 0;
          },
          handleInputOptimized() {
              if (this.inputTimer) {
                  clearTimeout(this.inputTimer);
              }
              
              this.inputTimer = setTimeout(() => {
                  this.wordcnt = this.voicetext.length;
                  if (this.wordcnt > STATIC_CONFIG.text_maxlen) {
                      this.voicetext = this.voicetext.substring(0, STATIC_CONFIG.text_maxlen);
                      this.wordcnt = STATIC_CONFIG.text_maxlen;
                  }
              }, 100);
          },
          getPresetTextCount() {
              let count = 0;
              let index = 1;
              while (this.$te(`txt2voice.preset_texts.${index}`)) {
                  count++;
                  index++;
              }
              return count;
          },
          setPresetText(index) {
              this.activePresetIndex = index;
              this.voicetext = this.$t(`txt2voice.preset_texts.${index}.text`);
              this.wordcnt = this.voicetext.length;
              this.handleInputOptimized();
          },
          setupEventListeners() {
              // 错误处理监听
              this.errorHandler = (event) => reportError(event.error, 'window.error')
              this.rejectionHandler = (event) => reportError(event.reason, 'unhandledrejection')
              window.addEventListener('error', this.errorHandler)
              window.addEventListener('unhandledrejection', this.rejectionHandler)
  
              // 初始化下载弹窗引用
              this.$refs.popupDownload = this.$refs.popupDownload || {}
  
              // 可选: 初始化性能监控
              // try {
              //     onLCP((metric) => reportWebVital(metric))
              // } catch (error) {
              //     reportError(error, 'speechma Failed to initialize INP monitoring')
              // }
          },
          selectVoice(option) {
              this.selectModel = option.value;
              this.selectModelWavplay = option.wavplay;
              this.showAudioControls = false;
              
              // 如果有正在播放的音频，停止播放
              if (this.isPreviewPlaying) {
                  this.$refs.audio_main.pause();
                  this.isPreviewPlaying = false;
                  this.currentPreviewVoice = null;
              }
          },
          async previewVoice(option) {
              event.stopPropagation();
              
              const audio = this.$refs.audio_main;
              const audioUrl = `${STATIC_CONFIG.api_host}${option.wavplay}`;
              
              // 如果当前正在播放同一个音频，则暂停
              if (this.isPreviewPlaying && this.currentPreviewVoice === option.value) {
                  audio.pause();
                  this.isPreviewPlaying = false;
                  this.currentPreviewVoice = null;
                  return;
              }
  
              // 设置当前预览的音频
              this.currentPreviewVoice = option.value;
              
              try {
                  // 如果有其他音频在播放，先停止
                  if (this.isPreviewPlaying) {
                      audio.pause();
                      this.isPreviewPlaying = false;
                  }
  
                  // 检查音频是否已经加载并且可以播放
                  if (audio.src === audioUrl && audio.readyState >= 2) {
                      audio.currentTime = 0;
                      await audio.play();
                  } else {
                      // 只有在需要加载新音频时才显示 loading
                      this.isPreviewLoading = true;
                      await this.playAudio(audio, option.wavplay);
                  }
                  
                  this.isPreviewPlaying = true;
                  
                  // 监听播放结束
                  audio.onended = () => {
                      this.isPreviewPlaying = false;
                      this.currentPreviewVoice = null;
                      audio.onended = null;
                  };
              } catch (error) {
                  this.isPreviewPlaying = false;
                  this.currentPreviewVoice = null;
                  console.error('Preview playback failed:', error);
                  reportError(error, 'previewVoice');
              } finally {
                  this.isPreviewLoading = false;
              }
          }
      },
      async created() {
          // 1. 优先加载关键数据
          const criticalData = {
              selectLang: this.$store.state.selectLang,   
              voicetext: this.$store.state.presetText || '', // 设置默认值避免undefined
          }
          Object.assign(this, criticalData)
          
          // 2. 延迟加载非关键数据
          queueMicrotask(() => {
              this.selectLangModels = this.$store.state.selectLangModels
              this.selectModel = this.$store.state.selectModel
              this.selectModelWavplay = this.$store.state.selectModelWavplay
              this.wordcnt = this.voicetext.length
              this.activePresetIndex = 1
          })
          if (this.isLoggedIn && this.currentUser) {
              this.getUserInfo(this.currentUser.email);
          }
      },
      mounted () {
          this.$nextTick(() => {
            this.langsModels = this.$store.state.langsModels
            this.allLangs = this.$store.state.allLangs
            this.pricing_href = '/' + this.$store.state.lang + '/pricing'
            const textarea = document.getElementById('voicetextarea')
            if (textarea) {
                textarea.focus()
            }
          })

          // 使用 requestIdleCallback 在浏览器空闲时初始化非关键功能
          if ('requestIdleCallback' in window) {
              requestIdleCallback(() => {
                  this.initializePopups()
                  this.setupEventListeners()
              }, { timeout: 2000 }) // 设置2秒超时,确保最终会执行
          } else {
              // 降级处理: 如果浏览器不支持 requestIdleCallback
              setTimeout(() => {
                  this.initializePopups()
                  this.setupEventListeners()
              }, 0)
          }
      },
      beforeUnmount() {
          if (this.errorHandler) {
              window.removeEventListener('error', this.errorHandler)
          }
          if (this.rejectionHandler) {
              window.removeEventListener('unhandledrejection', this.rejectionHandler)
          }
          const audio = this.$refs.audio_main;
          if (audio) {
              audio.pause();
              audio.src = '';
              audio.onended = null;
              audio.onerror = null;
          }
      }
  }
  </script>
  
  <style scoped>
  </style>